// assets
import {
    IconPlug,
    Icon24Hours,
    IconClockRecord,
    IconAntenna,
    IconChartHistogram,
    IconUsers,
    IconStatusChange,
    IconHeartRateMonitor,
    IconCloudDownload,
    IconRouter
} from '@tabler/icons-react';

// constant
const icons = {
    IconPlug,
    Icon24Hours,
    IconClockRecord,
    IconAntenna,
    IconChartHistogram,
    IconUsers,
    IconStatusChange,
    IconHeartRateMonitor,
    IconCloudDownload,
    IconRouter
};

// ==============================|| REALTIME MENU ITEMS ||============================== //

const settings = {
    id: 'setting',
    title: 'Settings',
    type: 'group',
    children: [
        {
            id: 'setting-general',
            title: 'General',
            type: 'collapse',
            icon: icons.IconStatusChange,
            children: [
                {
                    id: 'general-default',
                    title: 'Default',
                    type: 'item',
                    url: '/settings/default/defaultSetting',
                    breadcrumbs: false
                },
                {
                    id: 'setting-data-gateway',
                    title: 'Data Gateway',
                    type: 'item',
                    url: '/settings/datatoserver/gateway',
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'setting-iiot-gatway',
            title: 'IIOT Gateway',
            type: 'collapse',
            icon: icons.IconPlug,
            permissions: 'isIiotGateway',
            children: [
                {
                    id: 'setting-iiot-gateway-devices',
                    title: 'Gateway',
                    type: 'item',
                    url: '/settings/modbus/gateway',
                    icon: icons.IconRouter,
                    breadcrumbs: false
                },
                {
                    id: 'setting-connection',
                    title: 'Connection',
                    type: 'item',
                    url: '/settings/modbus/devices',
                    icon: icons.IconPlug,
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'setting-dataReceiver',
            title: 'Data Receiver',
            type: 'collapse',
            icon: icons.IconCloudDownload,
            permissions: ['isHttps', 'isMqtts'], // Multiple permissions
            children: [
                {
                    id: 'setting-dataReceiver-requestMapping',
                    title: 'Request Mapping',
                    permissions: 'isRawData',
                    type: 'item',
                    url: '/settings/dataReceiver/requests',
                    breadcrumbs: false
                },
                {
                    id: 'setting-dataReceiver-convertedData-requestMapping',
                    title: 'Converted Data Map',
                    permissions: 'isPreComputedData',
                    type: 'item',
                    url: '/settings/dataReceiver/convertedDataRequests',
                    breadcrumbs: false
                },
                {
                    id: 'setting-dataReceiver-guide',
                    title: 'Configuration Guide',
                    type: 'collapse',
                    permissions: ['isHttps', 'isMqtts'], // Multiple permissions
                    children: [
                        {
                            id: 'setting-dataReceiver-httpServer-configuration',
                            title: 'HTTP',
                            type: 'item',
                            permissions: 'isHttps',
                            url: '/settings/dataReceiver/httpserver',
                            breadcrumbs: false
                        },
                        {
                            id: 'setting-dataReceiver-mqtt-configuration',
                            title: 'MQTT',
                            permissions: 'isMqtts',
                            type: 'item',
                            url: '/settings/dataReceiver/mqttserver',
                            breadcrumbs: false
                        }
                    ]
                }
            ]
        },
        {
            id: 'setting-realtime',
            title: 'Realtime',
            type: 'item',
            url: '/settings/realtime/screenconfig',
            icon: icons.Icon24Hours,
            breadcrumbs: false
        },
        {
            id: 'setting-scadaScreens',
            title: 'Screens',
            type: 'item',
            url: '/settings/scada/screenconfig',
            icon: icons.IconHeartRateMonitor,
            breadcrumbs: false
        },
        {
            id: 'setting-pollingInterval',
            title: 'Historical Data',
            type: 'collapse',
            icon: icons.IconChartHistogram,
            children: [
                {
                    id: 'historical-addr-config',
                    title: 'Address Configuration',
                    type: 'item',
                    url: '/settings/pollingInterval/config',
                    breadcrumbs: false
                },
                {
                    id: 'historical-chart-config',
                    title: 'Graph Configuration',
                    type: 'item',
                    url: '/settings/pollingInterval/chart-config',
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'setting-trigger',
            title: 'Trigger',
            type: 'collapse',
            icon: icons.IconAntenna,
            children: [
                {
                    id: 'trigger-addr-config',
                    title: 'Address Configuration',
                    type: 'item',
                    url: '/settings/trigger/config',
                    breadcrumbs: false
                },
                {
                    id: 'trigger-chart-config',
                    title: 'Graph Configuration',
                    type: 'item',
                    url: '/settings/trigger/chart-config',
                    breadcrumbs: false
                }
            ]
        },

        {
            id: 'setting-user',
            title: 'Users',
            type: 'item',
            url: '/settings/userManagement/users',
            icon: icons.IconUsers,
            breadcrumbs: false
        }
    ]
};

export default settings;
